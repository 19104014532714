import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { CaseCard } from "./caseCard"

export const Cases = () => {
  const {
    cases,
  } = useStaticQuery(
    graphql`
        query {
            cases: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___title}) {
                edges {
                    node {
                        frontmatter {
                            title
                            titleShort
                            subTitle
                            image {
                                extension
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        height: 160
                                        quality: 100
                                        placeholder: NONE
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                            ingress
                            by
                            byImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: NONE
                                        formats: [AUTO, WEBP, AVIF]
                                    )
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `
  )

  return <div id="cases" className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    <div className="relative max-w-7xl mx-auto">
      <div className="text-center">
        <h2 className="text-3xl tracking-tight font-extrabold text-black sm:text-4xl">
          Leveranser
        </h2>
        {/*<p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">*/}
        {/*  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.*/}
        {/*</p>*/}
      </div>
      <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        {cases.edges.map(edge => {
          return CaseCard(edge);
        })}
      </div>
      {/*<div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">*/}
      {/*  <div className="rounded-md shadow">*/}
      {/*    <Link to="/case"*/}
      {/*          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary md:py-4 md:text-lg md:px-10">*/}
      {/*      Sjå fleire*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  </div>
}