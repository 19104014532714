import React from "react"

export const Services2 = () => {
  return <div id="services" className="relative pt-16 pb-32 overflow-hidden">
    <div className="relative">
      <div className="text-center pb-8">
        <h2 className="text-3xl tracking-tight font-extrabold text-black sm:text-4xl">
          Tjenester
        </h2>
        <p className="mt-3 max-w-2xl mx-auto text-xl sm:mt-4">
          Vi tilbyr rådgivning innen strategi-, konsept- og softwareutvikling. Vi jobber på
          prosjektbasis, men kan også ta større oppdrag med oppbygging av
          selskap/avdelinger gjennom en Management for Hire avtale.
        </p>
      </div>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-black">
                Prosjektledelse
              </h2>
              <p className="mt-4 text-lg">
                Planlegging, koordinering og gjennomføring av prosjekter er grunnelement for alt vi arbeider med. Også de andre tjenestene vi leverer vil være organisert og gjennomført som prosjekter. Vi tar også på oss større prosjektoppdrag som relateres til forretningsutvikling og hvor god planlegging, koordinering av ressurser, fremdrift og kontroll på økonomi er sentrale element. I noen tilfeller gjennomføres disse som Management for hire oppdrag.
              </p>
              {/*<div className="mt-6">*/}
              {/*  <a href="#contact"*/}
              {/*     className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white primary-color-bg hover:primary-color-bg-dark">*/}
              {/*    Kontakt oss*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-black">
                Konseptutvikling
              </h2>
              <p className="mt-4 text-lg">
                Vi bistår bedrifter med å forbedre eksisterende og nye forretningskonsepter. Dette kan omfatte nye eller forbedrede produkter og tjenester, helt nye forretningsideer eller forretningsmodeller. Kunde-/brukerinnsikt og å forstå hva som har verdi for kunden/brukeren er sentralt i denne utviklingen. Mange forretningskonsepter vil i dag ha et digitalt innhold enten helt eller delvis. Som del av konseptutviklingen kan vi også gjennomføre prototyping av digitale løsninger.
              </p>
              {/*<div className="mt-6">*/}
              {/*  <a href="#contact"*/}
              {/*     className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white primary-color-bg hover:primary-color-bg-dark">*/}
              {/*    Kontakt oss*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-black">
                Strategiutvikling
              </h2>
              <p className="mt-4 text-lg">
                Vi hjelper bedrifter med å utvikle bedre strategier for å utnytte selskapet kapasiteter optimalt.
                Hvordan et strategioppdrag legges opp avhenger av bedriftens behov. Det kan omfatte en totalstrategi for hele bedriften, eller en delstrategier for enkelte områder som f.eks. optimalisering av verdikjeden, utvikling av organisasjonen eller salg- og markedsstrategi.
              </p>
              {/*<div className="mt-6">*/}
              {/*  <a href="#contact"*/}
              {/*     className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white primary-color-bg hover:primary-color-bg-dark">*/}
              {/*    Kontakt oss*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-black">
                Innovasjon
              </h2>
              <p className="mt-4 text-lg">
                En ren innovasjonsprosess vil gjerne inneholde elementer av de ovennevnte tjenestene. Vi bruker her i hovedsak Doblins metoder som vil avdekke potensialet for innovasjon innen bl.a.;
              </p>
              <ul className="text-lg list-disc py-4 px-4">
                <li>Inntektsmodeller – nye måter bedriften kan tjene penger</li>
                <li>Nettverk – hvem og hvordan bør man samarbeide</li>
                <li>Produkter/tjenester – forbedring av eksisterende eller helt nye</li>
                <li>Organisasjonen og prosesser – effektivisering i hvordan vi jobber</li>
                <li>Kundeopplevelser</li>
              </ul>
              {/*<div className="mt-6">*/}
              {/*  <a href="#contact"*/}
              {/*     className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white primary-color-bg hover:primary-color-bg-dark">*/}
              {/*    Kontakt oss*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-black">
                IT-løsninger
              </h2>
              <p className="mt-4 text-lg">
                Vi utvikler IT-løsninger tilpasset den enkelte bedrifts behov. Dette kan være nye heldigitale løsninger, men en annen vanlig variant er databaseløsninger som lagres/driftes i skyen og som integreres med allerede eksisterende systemer. Slike løsninger kan vi også tilby som SaaS løsninger og drifte for selskaper.
                {/*<br /><br />*/}
                {/*Alle våre oppdrag gjennomføres i for av et Management for hire oppdrag eller et prosjekt hvor vi har prosjektledelsen.*/}
              </p>
              {/*<div className="mt-6">*/}
              {/*  <a href="#contact"*/}
              {/*     className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white primary-color-bg hover:primary-color-bg-dark">*/}
              {/*    Kontakt oss*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/*<div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">*/}
        {/*  <div>*/}
        {/*    <div className="mt-6">*/}
        {/*      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">*/}
        {/*        Verktøy/Metodikk*/}
        {/*      </h2>*/}
        {/*      <p className="mt-4 text-lg text-gray-500">*/}
        {/*        Gode verktøy og god arbeidsmetodikk er essensielt for å arbeide effektivt og målrettet.*/}
        {/*        <br /><br />*/}
        {/*        Vi benytter <a href="https://www.resop.no">Resop</a> som digital plattform hvor kunden får tilgang til alle konsultverktøyene, og vi kan kjøre virtuelle workshops og arbeidsmøter, og jobbe simultant på den aktuelle verktøyene/metodene.*/}
        {/*        <br /><br />*/}
        {/*        Noen av de fagmiljøene vi henter inspirasjon og våre verktøy fra er:*/}
        {/*        <br />*/}
        {/*        <a href="https://www.strategyzer.com/">Strategyzer / Osterwalder</a>*/}
        {/*        <br />*/}
        {/*        <a href="https://doblin.com/">Doblin</a>*/}
        {/*      </p>*/}
        {/*      /!*<div className="mt-6">*!/*/}
        {/*      /!*  <a href="#contact"*!/*/}
        {/*      /!*     className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white primary-color-bg hover:primary-color-bg-dark">*!/*/}
        {/*      /!*    Kontakt oss*!/*/}
        {/*      /!*  </a>*!/*/}
        {/*      /!*</div>*!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  </div>
}