import React from "react"
import Header from "./header"

export const Hero3 = () => {

  return <div className="relative overflow-hidden">
    <div className="relative pt-6 pb-16 sm:pb-24">
      <Header isDark={true} />

      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 lg:py-5">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-200 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">Vi skaper gode</span>
            <span className="block text-primary xl:inline"> løsninger</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-300 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Vi benytter anerkjente metoder og sikrer at hvert oppdrag gjennomføres til vi ser at det fungerer i praksis.
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <a href="#contact"
                 className="cta-button">
                Kontakt oss
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
}