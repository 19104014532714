import React from "react"
import Seo from "../components/seo"
import { Cases } from "../components/cases"
import { Hero3 } from "../components/hero3"
import { Footer } from "../components/footer"
import { About3 } from "../components/about3"
import { Services2 } from "../components/services2"
import { Contact } from "../components/contact"
import Layout from "../components/layout"

const IndexPage = () => {
  return <Layout>
    <Seo title={"Bergset AS"}/>
    <div className="bg-gray-900">
      {/*<Hero2 />*/}
      {/*<div className="bg-gray-200">*/}
      {/*  <Hero />*/}
      {/*  <LogoCloud />*/}
      {/*</div>*/}
      <div className="bg-gray-900">
        <Hero3 />
      </div>
      {/*<div className="bg-gray-50">*/}
      {/*  <About />*/}
      {/*</div>*/}
      {/*<div className="bg-gray-50">*/}
      {/*  <About2 />*/}
      {/*</div>*/}
      <div className="bg-gray-100 text-gray-900">
        <About3 />
      </div>
      <div className="bg-gray-50 text-gray-900">
        <Services2 />
      </div>
      <div className="bg-gray-100 text-gray-900">
        <Cases />
      </div>
      <div className="bg-gray-900 text-gray-200">
        <Contact />
      </div>
      <div className="bg-gray-900 text-gray-200">
        <Footer />
      </div>
    </div>
  </Layout>
}

export default IndexPage
