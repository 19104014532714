import React from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-google-recaptcha"

const RECAPTCHA_KEY = '6Lfj8D4gAAAAAH0n5qb7teJV_bHcIjq3hS_cAgrT'

const ContactForm = () => {
    const [state, setState] = React.useState({})
    const recaptchaRef = React.createRef()
    const [buttonDisabled, setButtonDisabled] = React.useState(true)

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    function encode(data) {
        return Object.keys(data)
            .map(
                (key) =>
                    encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
            )
            .join("&");
    }

    const handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                'g-recaptcha-response': recaptchaValue,
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }

    return (
        <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-recaptcha="true"
            netlify-honeypot="bot-field"
            action="/takk"
            onSubmit={handleSubmit}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        >
            <noscript>
                <p>This form won’t work with Javascript disabled</p>
            </noscript>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div>
                <label htmlFor="first_name" className="block text-sm font-medium text-gray-200">
                    Fornavn
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        autoComplete="given-name"
                        required={true}
                        onChange={handleChange}
                        className="text-gray-900 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div>
                <label htmlFor="last_name" className="block text-sm font-medium text-gray-200">
                    Etternavn
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        autoComplete="family-name"
                        required={true}
                        onChange={handleChange}
                        className="text-gray-900 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium text-gray-200">
                    Organisasjon
                </label>
                <div className="mt-1">
                    <input
                        type="text"
                        name="company"
                        id="company"
                        autoComplete="organization"
                        required={true}
                        onChange={handleChange}
                        className="text-gray-900 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-200">
                    E-post
                </label>
                <div className="mt-1">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required={true}
                        onChange={handleChange}
                        className="text-gray-900 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="phone_number" className="block text-sm font-medium text-gray-200">
                    Telefon
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        autoComplete="tel"
                        required={true}
                        onChange={handleChange}
                        className="text-gray-900 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-medium text-gray-200">
                    Melding
                </label>
                <div className="mt-1">
                    <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="text-gray-900 py-3 px-4 block w-full shadow-sm focus:ring-primary focus:border-primary border-gray-300 rounded-md"
                        defaultValue={''}
                        required={true}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="sm:col-span-2">
                <Recaptcha
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_KEY}
                    size="normal"
                    id="recaptcha-google"
                    onChange={() => setButtonDisabled(false)}
                />
            </div>
            <div className="sm:col-span-2">
                <button
                    type="submit"
                    disabled={buttonDisabled}
                    className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-secondary bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Send
                </button>
            </div>
        </form>
    )
}

export default ContactForm
