import React from "react"
import Link from "gatsby-link"
import { GatsbyImage } from "gatsby-plugin-image"

export const CaseCard = (edge) => {
  return <div className="flex flex-col rounded-lg shadow-lg overflow-hidden bg-white">
    <div className="flex-shrink-0 mx-auto h-44 pt-6">
      <Link to={edge.node.fields.slug}>
        {edge.node.frontmatter.image.extension === 'svg' ?
          <img
            className="image-fix"
            style={{height: '160px'}}
            src={edge.node.frontmatter.image.publicURL}
            alt={edge.node.title} />
          :
          <GatsbyImage
            className="image-fix"
            image={edge.node.frontmatter.image.childImageSharp.gatsbyImageData}
            alt={edge.node.title}
          />
        }
      </Link>
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-sm font-medium">
          <Link to={edge.node.fields.slug} className="hover:underline">
            {edge.node.frontmatter.subTitle}
          </Link>
        </p>
        <Link to={edge.node.fields.slug} className="block mt-2">
          <p className="text-xl font-semibold text-gray-900">
            {edge.node.frontmatter.title}
          </p>
          <p className="mt-3 text-base">
            {edge.node.frontmatter.ingress}
          </p>
        </Link>
      </div>
      <div className="mt-6 flex items-center">
        <div className="flex-shrink-0">
          <span className="sr-only">{edge.node.frontmatter.by}</span>
          {edge.node.frontmatter.by === 'Kristoffer Strøm Bergset' ?
            <GatsbyImage className="h-10 w-10 rounded-full"
                         image={edge.node.frontmatter.byImage.childImageSharp.gatsbyImageData}
                         alt="" />
            :
            <GatsbyImage className="h-10 w-10 rounded-full"
                         image={edge.node.frontmatter.byImage.childImageSharp.gatsbyImageData}
                         alt="" />
          }
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-900">
            {edge.node.frontmatter.by}
          </p>
        </div>
      </div>
    </div>
  </div>
}