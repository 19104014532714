import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export const About3 = () => {

  const {
    kristofferImage,
    rogerImage,
  } = useStaticQuery(
    graphql`
        query {
            kristofferImage: file(
                relativePath: { eq: "kristoffer.jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData
                }
            }
            rogerImage: file(
                relativePath: { eq: "roger.jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `
  )

  return <div id="about">
    <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div className="space-y-12">
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-black sm:text-4xl">
            Om oss
          </h2>
          <p className="mt-4 max-w-4xl mx-auto text-center text-xl">
            Bergset AS er et konsulentselskap som arbeider med forretningsutvikling. Våre kunder er både private og offentlige virksomheter. Vi bistår våre kunder med ledelses- og løsningskapasitet i oppgaver som normalt ligger utenfor deres ordinære drift i dag. Tjenestene omfatter bl.a strategi-, konsept-, og softwareutvikling. Vi jobber på prosjektbasis, og tar her også større oppdrag med oppbygging av selskap/avdelinger gjennom en Management for Hire avtale. Vårt team har lang praktisk erfaring innen våre tjenesteområder.
          </p>
          <p className="mt-4 max-w-4xl mx-auto text-center text-xl">
            I tillegg til å jobbe som rådgivere for kunder, arbeider vi med utvikling av egne bedrifter og prosjekter gjennom selskapet Bergset Holding AS. Per dato er Bergset AS et rent familieselskap og består av;
          </p>
        </div>

        <ul className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0">

          <li>
            <div className="text-center sm:text-left space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
              <div className="">
                <GatsbyImage className="object-cover shadow-lg rounded-lg"
                     image={rogerImage.childImageSharp.gatsbyImageData}
                     alt="" />
              </div>
              <div className="sm:col-span-2">
                <div className="text-center sm:text-left space-y-4">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3 className="text-black">Roger Bergset</h3>
                    <p className="text-gray-700">CEO</p>
                  </div>
                  <div className="text-lg">
                    <p className="text-gray-600">
                      Siviløkonom
                    </p>
                  </div>
                  <ul className="justify-center sm:justify-start flex space-x-5">
                    <li>
                      <a href="mailto:roger@bergset.no" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">E-post</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="/CV-Roger-Bergset-2021.pdf" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">CV</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/roger-bergset-2140862/" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                          <path fill-rule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clip-rule="evenodd"/>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="text-center sm:text-left space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
              {/*h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4*/}
              <div className="">
                <GatsbyImage className="object-cover shadow-lg rounded-lg"
                     image={kristofferImage.childImageSharp.gatsbyImageData}
                     alt="" />
              </div>
              <div className="sm:col-span-2">
                <div className="space-y-4">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3 className="text-black">Kristoffer Strøm Bergset</h3>
                    <p className="text-gray-700">CTO</p>
                  </div>
                  <div className="text-lg">
                    <p className="text-gray-600">
                      Dataingeniør/programutvikler
                    </p>
                  </div>
                  <ul className="justify-center sm:justify-start flex space-x-5">
                    <li>
                      <a href="mailto:kristoffer@bergset.no" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">E-post</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://kristoffer.bergset.no" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Nettside</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://kristoffer.bergset.no/cv" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">CV</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://github.com/krisberg" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Github</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                          <path fill-rule="evenodd"
                                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                                clip-rule="evenodd"/>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/kristoffer-str%C3%B8m-bergset-4913756a/" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                          <path fill-rule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clip-rule="evenodd"/>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>

        </ul>
      </div>
    </div>
    <p className="pb-12 max-w-4xl mx-auto text-center text-xl">
      Vi har i tillegg et nettverk av samarbeidspartnere som vi trekker inn i relevante prosjekter.
    </p>

  </div>
}