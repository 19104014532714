import React from "react"
import ContactForm from "./contactForm"

export const Contact = () => {
  return <div id="contact" className="bg-secondary-light py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
    <div className="relative max-w-xl mx-auto">
      <div className="text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          Kontakt Oss
        </h2>
        {/*<p className="mt-4 text-lg leading-6 text-gray-500">*/}
        {/*  Har du spørsmål du lurer på, mulige prosjekt vi kan hjelpe deg med eller er du interessert i samarbeid?*/}
        {/*</p>*/}
      </div>
      <div className="mt-12">
        <ContactForm />
      </div>
    </div>
  </div>
}
